export class SearchByColumn {
  constructor(controller, params){
    this.controller = controller
    this.params = params
  }

  clickColumnSearch(e) {
    e.preventDefault()
    const index = e.params.index
    const wrapper = this.controller.searchColumnWrapperTargets[index]
    const input = wrapper.querySelector('input')
    input.focus()
  }

  closeColumnSearch(e) {
    e.preventDefault()
    const index = e.params.index
    const wrapper = this.controller.searchColumnWrapperTargets[index]
    const input = wrapper.querySelector('input')
    const label = wrapper.querySelector('label')
    input.value = ''
    label.classList.remove('tw--translate-y-[0.9rem]', 'tw-scale-[0.8]', 'tw-text-primary')
    e.currentTarget.classList.remove('tw-opacity-100')
    this.controller.search()
  }

  changeSearchColumnInput(e) {
    const element = e.currentTarget
    const index = e.params.index
    const wrapper = this.controller.searchColumnWrapperTargets[index]
    const label = wrapper.querySelector('label')
    const button = wrapper.querySelector('button[data-id=\'search-close\']')
    if (element.value !== '') {
      label.classList.add('tw--translate-y-[0.9rem]', 'tw-scale-[0.8]', 'tw-text-primary')
      button.classList.add('tw-opacity-30')
      button.classList.add('hover:tw-opacity-60')
    } else {
      label.classList.remove('tw--translate-y-[0.9rem]', 'tw-scale-[0.8]', 'tw-text-primary')
      button.classList.remove('tw-opacity-30')
      button.classList.remove('hover:tw-opacity-60')
    }
    this.controller.search(1200)
  }
}
