import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/l10n/fr.js'
import 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/l10n/it.js'
import 'flatpickr/dist/l10n/pt.js'
import RangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'

const flatpickrOneMonthWith = 308

require('flatpickr/dist/flatpickr.min.css')

// to instantiate
// data-controller="flatpickr"
// Always pass in the locale / language code
// data-flatpickr-locale-value:'I18n.locale

// define input field for datepicker (optional).
// If not defined, it will launch flatpick on the element where it was defined.
// data-flatpickr-target='input'

// to show picker with time
// data-flatpickr-enable-time-value="true"

// to enable a range picker. Also a second inputTargets must be defined
// data-flatpickr-range-value='true'
// data-flatpickr-target='input'

// sometimes for properly preloading ranges, dates should be deliberately set via javascript
// --> using the stimulus value api
// data-flatpickr-default-dates-value=['2022-05-08', '2022-05-12']

// to show multiple months
// data-flatpickr-multiple-months-value='true'
// if multiple months are shown, and number of months shown should be automatically resized after window resize
// add to element where controller is defined
// data-action="resize@window->flatpickr#updateShowMonth"

// to show event dates in the calender (like bookings)
// data-flatpickr-show-event-dates-value='true'
// create global variable with [ {start_date: #DATE#, end_date: #DATE }]

// To clear the dates from all input fields:
// data-action='flatpickr#clear'

export default class extends Controller {
  static targets = ['input', 'positionElement', 'check'];
  static values = {
    enableTime: Boolean,
    noCalendar: Boolean,
    minuteIncrement: { type: Number, default: 5 },
    range: Boolean,
    defaultDate: Array,
    inline: Boolean,
    multipleMonths: Boolean,
    showEventDates: Boolean,
    locale: String,
    eventDates: Array,
  }

  initialize() {
    let locale = this.localeValue || 'de'
    if (locale.match(/-/)) {
      locale = locale.split('-')[0]
    }
    const defaultSettings = {
      locale: locale,
      altFormat: 'd.m.Y',
      // altFormat:  this.localeValue == 'fr' ? "d/m/Y" : "d.m.Y",
      time_24hr: true,
      weekNumbers: true,
    }

    flatpickr.setDefaults(defaultSettings)
    flatpickr.l10ns.en.firstDayOfWeek = 1
  }

  connect() {
    this.flatpickrElement = (this.hasInputTarget ? this.inputTarget : this.element)

    // Check if an inputTarget was defined. If defined, launch calendar on inputTarget
    // else launch directly on the element
    this.flatpickr = flatpickr(this.flatpickrElement, this.setLoadOptions())
  }

  setLoadOptions() {
    const options = {}
    // sets / preloads dates via javascript
    if (this.hasDefaultDateValue) {
      options['defaultDate'] = this.defaultDateValue
    }
    if (this.hasPositionElementTarget) {
      options['positionElement'] = this.positionElementTarget
    }
    if (this.inlineValue == true) {
      options['inline'] = true
    }
    if (this.enableTimeValue == true) {
      options['enableTime'] = true
      options['altFormat'] = flatpickr.defaultConfig.altFormat + ' ' + 'H:i'
    } // flatpick default is false
    // only set the minuteIncrement, if the Time is enabled
    if (this.enableTimeValue == true) {
      options['minuteIncrement'] = this.minuteIncrementValue
    }
    if (this.hasMultipleMonthsValue) {
      options['showMonths'] = this.maxMultipleMonth()
      // if multiple months are shown, this doesn't work
      options['weekNumbers'] = false
    }

    if (this.rangeValue === true) {
      options['mode'] = 'range'
      if (this.inputTargets.length == 2) {
        options['plugins'] = [new RangePlugin({ input: this.inputTargets[1] })]
      }
    }

    if (this.hasNoCalendarValue) {
      options['enableTime'] = true
      options['noCalendar'] = true
      options['altFormat'] = 'H:i'
      options['minuteIncrement'] = this.minuteIncrementValue
    }
    // add the plugin range for range selecting on two inputs

    if (this.flatpickrElement.tagName == 'INPUT' && this.flatpickrElement.getAttribute('type') != 'hidden') {
      options['allowInput'] = true // flatpickr default is false
      options['altInput'] = true // flatpickr default is false
    }
    if (typeof this.eventDatesValue != 'undefined' && this.showEventDatesValue == true) {
      const eventDates = this.eventDatesValue
      options['onDayCreate'] = function(dObj, dStr, fp, dayElem) {
        let i
        let currentEventDate
        let endDate
        for (i = 0; i < eventDates.length; i++) {
          currentEventDate = new Date(new Date(eventDates[i].start_date).setHours(0, 0, 0, 0))
          endDate = new Date(new Date(eventDates[i].end_date).setHours(0, 0, 0, 0))
          if (dayElem.dateObj < currentEventDate) {
            break
          }
          if (dayElem.dateObj.getTime() == currentEventDate.getTime()) {
            dayElem.classList.add('eventStart')
            break
          }
          if (dayElem.dateObj > currentEventDate && dayElem.dateObj < endDate) {
            dayElem.classList.add('inBetween')
            break
          }
          if (dayElem.dateObj.getTime() == endDate.getTime()) {
            dayElem.classList.add('eventEnd')
            break
          }
        }
      }
    }
    return options
  }

  open() {
    this.flatpickr.open()
  }

  clear() {
    this.flatpickr.clear()
  }

  updateDate(event) {
    if (event.currentTarget instanceof HTMLAnchorElement) event.preventDefault()
    const newDate = event.currentTarget.dataset['newDate']
    this.flatpickr.setDate(newDate, true)
  }

  updateShowMonth() {
    const numMonth = this.maxMultipleMonth() || 1
    if (typeof this.flatpickr !== 'undefined' &&
      typeof this.flatpickr.config.showMonths !== 'undefined' &&
      numMonth != this.flatpickr.config.showMonths) {
      const newWidth = numMonth * flatpickrOneMonthWith + 'px'
      this.flatpickr.set('showMonths', numMonth)
      this.flatpickr.redraw()
      document.getElementsByClassName('flatpickr-calendar')[0].style.width = newWidth
      document.getElementsByClassName('flatpickr-rContainer')[0].style.width = newWidth
    }
  }

  maxMultipleMonth() {
    const width = this.element.offsetWidth
    return Math.floor(width / flatpickrOneMonthWith)
  }
}
